(function($){


  jQuery.fn.foxlazy = function(threshold, callback) {

    var $w = $(window),
        th = threshold || 100,
        retina = window.devicePixelRatio > 1,
        attrib = retina ? "data-lazy-src-retina" : "data-lazy-src",
        images = this,
        loaded;

    this.one("foxlazy", function() {
      var source = this.getAttribute(attrib);
      source = source || this.getAttribute("data-lazy-src");
      if (source) {
        this.setAttribute("src", source);
        if ($(this).hasClass('s-img-switch')) {
          if ( this.getAttribute("data-s-sibling") ) {
            $(this).parent().find('.s-sibling-switch').css( 'background-image' , 'url(' + source + ')' );
          } else {
            $(this).parent().css( 'background-image' , 'url(' + source + ')' );
            $(this).hide();
          }

        }
      }
      if (typeof callback === "function") callback.call(this);
    });

    function foxlazy() {
      var inview = images.filter(function() {

        var $e = $(this);
        if ($e.parent().is(":hidden") || $e[0].classList.contains('rev-slidebg')) return;

        var scrollTop = $w.scrollTop(),// scrollTop
            scrollTopHeight = scrollTop + $w.height(), // scrollTopHeight
            offsetTop = $(this).parent().offset().top, // offsetTop
            offsetTopHeight = offsetTop + $e.height(); // offsetTopHeight

        return offsetTopHeight >= scrollTop - th && offsetTop <= scrollTopHeight + th;
      });

      loaded = inview.trigger("foxlazy");
      images = images.not(loaded);
    }

    $w.on("load.foxlazy scroll.foxlazy resize.foxlazy lookup.foxlazy", function(){
      foxlazy();
    });

    return this;

  };



})(jQuery);
